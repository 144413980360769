
import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {Grid} from '@mui/material';
import Container from '@mui/material/Container';
import Nav from './components/nav';
import Home from './pages/home';
import Opportunites from './pages/opportunites';
import Part from './pages/part';
import Parts from './pages/parts';
import Network from './pages/network';
import AddPart from './pages/addPart';
import Schedule from './pages/schedule';
import Fleet from './pages/fleet';
import Login from './pages/login';
import Consolidations from './pages/consolidations';
import NetworkDesign from './pages/networkDesign'; 
import UploadFleet from './pages/uploadFleet';
import CircularProgress from '@mui/material/CircularProgress';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";


const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.75rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  palette: {
    primary: {
      main: '#284db7',
    },
    secondary: {
      main: '#612785',
    },
    
  },
});

function App() {
  let token = undefined;
  let loggedIn = false
  if(localStorage.getItem('token')){
    token = localStorage.getItem('token');
    loggedIn = true;
  }else{
    loggedIn = false;
  }
  const [ports, setPorts] = React.useState([]);
  const [fleet , setFleet] = React.useState([]);
  const [loading , setLoading] = React.useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [fleetResponse, portsResponse] = await Promise.all([
          fetch(`https://port-dynamics.com/api/v1/logistics_manager/fleet/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
          }),
          fetch(`https://port-dynamics.com/ports/list/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
            }
          }),
        ]);

        if (!fleetResponse.ok ||  !portsResponse.ok) {
          throw new Error('Network response was not ok');
        }

        const [fleetData, portsData] = await Promise.all([
          fleetResponse.json(),
          portsResponse.json()
        ]);

        setFleet(fleetData);
        setPorts(portsData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        setLoading(false); // Ensure loading is set to false regardless of success or error
      }
    };

    fetchData();
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      {loading ?
         <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
            <Grid item xs={12} m={6}>
              <CircularProgress size={100}/>
            </Grid>
          </Grid>
      :(
        <>
           <Nav loggedIn={loggedIn}/>
            <Container sx={{
              maxWidth: {
                xs: '95%',   // Full width on extra small screens
                sm: '95%',   // Full width on small screens
                md: '95%',    // 90% width on medium screens
                lg: '95%',    // 80% width on large screens
                xl: '95%',    // 70% width on extra large screens
              },
              margin: 'auto', // Center the container
            }}>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Home />}/>
                  <Route path="/opportunites" element={loggedIn?(fleet.length?<Opportunites token={token} />:<Navigate to="/upload/fleet"/>):<Login/>} />
                  <Route path="/network" element={loggedIn?(fleet.length?<Network token={token}/>:<Navigate to="/upload/fleet"/>):<Login/>} />
                  <Route path="/network/design" element={loggedIn?(fleet.length?<NetworkDesign token={token}/>:<Navigate to="/upload/fleet"/>):<Login/>}/>
                  <Route path="/fleet/" element={loggedIn?(fleet.length?<Fleet token={token} fleetData={fleet}/>:<Navigate to="/upload/fleet/"/>):<Login/>} />
                  <Route path="/consolidations/" element={loggedIn?<Consolidations token={token}/>:<Login/>} />
                  <Route path="/part/:id" element={loggedIn?<Part token={token}/>:<Login/>} />
                  <Route path="/parts/" element={loggedIn?<Parts token={token} fleet={fleet} />:<Login/>} />
                  <Route path="/part/add" element={loggedIn?<AddPart token={token} fleetData={fleet}/>:<Login/>} />
                  <Route path="/schedule/:imo" element={loggedIn?<Schedule portsData={ports} token={token}/>:<Login/>} />
                  <Route path="/login" element={<Login/>} />
                  <Route path="/upload/fleet" element={<UploadFleet />} />
                </Routes>
              </BrowserRouter>
          
            </Container>
        </>

      )}
    </ThemeProvider>
  );
}

export default App;