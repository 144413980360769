import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import {Paper, Typography, Grid} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CustomAlert from '../components/alert';


export default function Part({token}){
    const statuses = [ "ordered",
        "in transit",
        "arrived",
        "in stock",
        "repositioned",
        "delivered",
    ];
    const {id} = useParams();
    const [spare, setSpare] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const[ success, setSuccess] = useState(false);
    const[fieldValid, setFieldValid] = useState(null);
    const updateStatus = () => {
        setSuccess(false);
        setError(false);
        fetch(
            `https://port-dynamics.com/api/v1/logistics_manager/spare/${id}/`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                },
                body: JSON.stringify(spare)
            }
        )
        .then(response => {
            if(response.status === 400){
                return response.text().then((text) => {
                let fieldErrors = {};
                   Object.keys(JSON.parse(text)).map((key) => {
                        fieldErrors[key] = {
                            valid: false,
                            message: JSON.parse(text)[key][0],
                            color: 'warning'
                        }
                        let tempFieldValid = fieldValid;
                        tempFieldValid[key] = fieldErrors[key];
                        setFieldValid(prevState => {
                            return {
                                ...prevState,
                                tempFieldValid
                            }
                        });
                        return null;
                    }
                    );
                    // form form level error banner to now show as these are field errors
                    setError(false);
                   throw new Error('Please fill in all required fields');
                });
            }
            if(response.status === 401){
                throw new Error('You are not authorized to perform this action');
            }
            if(response.status === 404){
                throw new Error('Spare not found');
            }
            if(response.status === 500){
                return response.text().then((text) => {
                    setError(true);
                    setErrorMessage(text);
                    throw new Error(text);
                });
            }
            return response.json();
        })
        .then(data => {
                setSpare(data);
                setLoading(false);
                setSuccess(true);
                setSuccessMessage('Spare updated successfully');
                setFieldValid({
                    act_kg:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    chargeable_kg:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    currency_symbol:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    description:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    dispatched:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    location_name:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    other_cost:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    purchase_cost:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    received:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    ship_name:{
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    imo: {
                        valid: true,
                        error: '',
                        message:'',
                        color: '',
                    },
                    status: {
                        valid: true,
                        error: '',
                        color: '',
                        message:'Please select status',
                    },
                });
            }
        )
        .catch(error => {
            console.log(error);
            setLoading(false);
        });
    }
    useEffect(() => {
        setFieldValid({
            act_kg:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            chargeable_kg:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            currency_symbol:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            description:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            dispatched:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            location_name:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            other_cost:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            purchase_cost:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            received:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            ship_name:{
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            imo: {
                valid: true,
                error: '',
                message:'',
                color: '',
            },
            status: {
                valid: true,
                error: '',
                color: '',
                message:'Please select status',
            },
        });
        fetch(
            `https://port-dynamics.com/api/v1/logistics_manager/spare/${id}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            }
        )
        .then(response => response.json())
        .then(data => {
            console.log(data);
                setSpare(data);
                setLoading(false);
            }
        )
        .catch(error => {
            setError(true);
            setErrorMessage(error.message);
            setLoading(false);
        });
    }, [id, token]);


    return (
        <Grid item xs={12} style={{ marginTop: '5px', marginBottom:'1%'}}>
            {loading ? (<CircularProgress />) : (
                <>
                {success ? <CustomAlert success message={successMessage} disappear={true}/>:null}
                {error ? <CustomAlert error message={errorMessage} disappear={false}/>:null}
                <Typography variant="h4" component="h4" sx={{marginTop:'2%'}}>Spare details</Typography>
                <Paper style={{ padding: '10px'}}>
                     <TextField
                        required
                        id="description"
                        inputProps={{readOnly:true}}
                        label="Description"
                        value={spare.description}
                        fullWidth
                        margin='normal'
                        error={!fieldValid.description.valid}
                        color={fieldValid.description.color}
                        helperText={fieldValid.description.message}
                        focused={!fieldValid.description.valid}
                
                        />
                     <TextField
                        id="imo"
                        label="Imo"
                        onChange={event => setSpare({...spare,imo: event.target.value})}
                        value={spare.imo}
                        fullWidth
                        margin='normal'
                        error={!fieldValid.imo.valid}
                        color={fieldValid.imo.color}
                        helperText={fieldValid.imo.message}
                        focused={!fieldValid.imo.valid}
                        />
                    <TextField
                        required
                        id="weight"
                        onChange={event => setSpare({...spare, act_kg: event.target.value})}
                        label="Weight in kgs"
                        type='number'
                        value={spare.act_kg}
                        fullWidth
                        margin='normal'
                        error={!fieldValid.act_kg.valid}
                        color={fieldValid.act_kg.color}
                        helperText={fieldValid.act_kg.message}
                        focused={!fieldValid.act_kg.valid}
 
                        />
                    <TextField
                        required
                        id="chargeable_kg"
                        label="Weight chargeable"
                        value={spare.chargeable_kg}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, chargeable_kg: event.target.value})}
                        error={!fieldValid.chargeable_kg.valid}
                        color={fieldValid.chargeable_kg.color}
                        helperText={fieldValid.chargeable_kg.message}
                        focused={!fieldValid.chargeable_kg.valid}
                        
               
                        />
                    <TextField
                        required
                        id="purchase_cost"
                        label="Purchase cost"
                        fullWidth
                        margin='normal'
                        value={spare.purchase_cost}
                        onChange={event => setSpare({...spare, purchase_cost: event.target.value})}
                        />
                    <TextField
                        required
                        id="other_cost"
                        label="Other cost"
                        value={spare.other_cost}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, other_cost: event.target.value})}
                        />
                    <TextField
                        required
                        inputProps={{readOnly:false}}
                        id="currency_symbol"
                        label="Currency"
                        value={spare.currency_symbol}
                        fullWidth
                        margin='normal'
                        />
                    <TextField
                        required
                        id="received"
                        label="Received on"
                        value={spare.received}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, received: event.target.value})}
                        error={!fieldValid.received.valid}
                        color={fieldValid.received.color}
                        helperText={fieldValid.received.message}
                        focused={!fieldValid.received.valid}
                        />
                    <TextField
                        required
                        id="dispatched"
                        label="Dispatched on"
                        value={spare.dispatched}
                        error={!fieldValid.dispatched.valid}
                        color={fieldValid.dispatched.color}
                        focused={!fieldValid.dispatched.valid}
                        helperText={fieldValid.dispatched.message}
                        fullWidth
                        margin='normal'
                        onChange={event => setSpare({...spare, dispatched: event.target.value})}
                        />
                     <TextField
                        required
                        id="status"
                        label="Status"
                        error={!fieldValid.status.valid}
                        color={fieldValid.status.color}
                        select
                        value={spare.status}
                        fullWidth
                        margin='normal'
                        helperText={fieldValid.status.message}
                        onChange={event => setSpare({...spare, status: event.target.value})}
                        focused={!fieldValid.status.valid}
                        >
                        {statuses.map((status, index) => (
                            <MenuItem key={index} value={status}>{status}</MenuItem>
                        ))}
                    </TextField>
                </Paper>
                    <Button variant="contained" color="primary" style={{marginTop:'10px'}} onClick={updateStatus}>Update</Button>
                </>
            )}
        </Grid>
    )
}