import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Typography, Box,  MenuItem, Menu } from '@mui/material';

export default function Nav({loggedIn}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const[loggedInState, setLoggedIn] = React.useState(false);
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (urlPath) => {
        setAnchorEl(false);
    };
    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.href = '/';
    }
    // Function to handle navigation and close the menu
    const handleNavigate = (urlPath) => {
      window.location.href = urlPath;
      handleMenuClose();
    };


    React.useEffect(() => {
        setLoggedIn(loggedIn);
    },[loggedIn]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
            <Box sx={{ flexGrow: 1,  display: { xs: 'block', md: 'none' } }}>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleMenuOpen}
                >
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => handleNavigate('/')}>Home</MenuItem>
                        <MenuItem onClick={() => handleNavigate('/opportunites')}>Opportunites</MenuItem>
                        <MenuItem onClick={() => handleNavigate('/consolidations')}>Consolidations</MenuItem>
                        <MenuItem onClick={() => handleNavigate('/parts')}>Stock</MenuItem>
                        <MenuItem onClick={() => handleNavigate('/fleet')}>Fleet</MenuItem>
                        {loggedInState?<MenuItem onClick={handleLogout}>Logout</MenuItem>:<MenuItem onClick={() => handleNavigate('/login')}>Login</MenuItem>}
                    </Menu>
                    <MenuIcon />
                </IconButton>
            </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            LogisticsManager
          </Typography>
          <Box sx={{ display: { xs: 'none', sm:'none', md: 'block' } }}>
                <Button href="/" color="inherit">Home</Button>
                <Button href="/opportunites" color="inherit">Opportunites</Button>
                <Button href="/consolidations" color="inherit">Consolidations</Button>
                <Button href="/parts" color="inherit">Stock</Button>
                <Button href="/fleet" color="inherit">Fleet</Button>
                <Button href="/network" color="inherit">Network</Button>
                <Button href="/network/design" color="inherit">Network design</Button>
                {loggedInState?<Button color="inherit" onClick={handleLogout}>Logout</Button>:<Button href="/login" color="inherit">Login</Button>}
         </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}