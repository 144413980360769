import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import {Paper, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import ConstructionIcon from '@mui/icons-material/Construction';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Warning';




export default function Consolidations({token}) {
    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [error, setError] = useState(null);
    const [hubs , setHubs] = useState([]);




    useEffect(() => {
        fetch('https://port-dynamics.com/api/v1/logistics_manager/locations/?d=y&type=central', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => response.json())
        .then(data => {
            const newData = data.map((item) => {
                return {label: item.location_name, id: item.location_id}
            })

            setLocations(newData);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }, [token]);
    const makeAPiCall = (event, newVal) => {
        if(!newVal){
            setError(null);
            setHubs([]);
            return;
        }
        fetch(`https://port-dynamics.com/api/v1/logistics_manager/location/?location_name=${newVal.label}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        })
        .then(response => {
            if(response.ok){
                return response.json();
            }else{
                throw new Error(response.statusText);
            }
        })
        .then(data => {
            setError(null);
            setHubs(data.hubs);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            setError(error.message);
        });
    }
    return (
        loading ? (
            <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
              <Grid item xs={12} m={6}>
                <CircularProgress size={100}/>
              </Grid>
            </Grid>
        ) :(
                <Grid container spacing={2} style={{marginTop:'5px'}}>
                    <Grid item xs={12} sm={12} style={{marginTop:'10px'}}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={locations}
                            sx={{ width: 300 }}
                            getOptionLabel={(option) => option.label}
                            onChange={makeAPiCall}
                            renderInput={params =>   
                                <TextField
                                    {...params}
                                    label="Select Location"
                                    variant="outlined"
                                    fullWidth
                                />
                            }
                            isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                            style={{width:'100%'}}
                        />
                    </Grid>

                    {error?
                        <Grid item xs={12} sm={12} lg={12}>
                            <Alert style={{marginTop:'50px'}} severity="error"><Typography component="h1" variant="h1">An error occured: {error}</Typography></Alert>
                        </Grid>
                        :null
                    }
                    {hubs.length?hubs.map(hub=>{
                        return <>
                                    <Grid item xs={12} sm={12} lg={12}>
                                    <Accordion>
                                        <AccordionSummary
                                        key={`accordion-summary${hub.link_description}`}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel-1-content-for-${hub.link_description}`}
                                        id={`panel-1-header-for-${hub.link_description}`}
                                        >
                                            <Typography sx={{ width: '35%', flexShrink: 0 }}>
                                                {hub.hub_name}
                                            </Typography>
                                            {
                                                hub.link_description==='express'? 
                                                <Chip label={hub.spares.length > 1? `${hub.spares.length} Express shipments`: `${hub.spares.length} Express shipment`} size="medium" sx={{backgroundColor:"#FFCC00"}} variant="outlined"
                                            />
                                            :hub.link_description==='standard'?
                                                <Chip label={hub.spares.length > 1? `${hub.spares.length} Standard shipments`: `${hub.spares.length} Standard shipment`} size="medium" sx={{backgroundColor:"#B5E6A2"}} variant="outlined"/>
                                                :hub.link_description==='urgent'?
                                                    <Chip label={hub.spares.length > 1? `${hub.spares.length} Urgent shipments`: `${hub.spares.length} Urgent shipment`} size="medium" sx={{backgroundColor:"red", color:'white'}} variant="outlined"/>
                                                :<Chip label={hub.spares.length > 1? `${hub.spares.length} No shipments`: `${hub.spares.length} No shipment`} size="medium" sx={{backgroundColor:"#CCECFF"}} variant="outlined"/>
                                            }
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          {hub.spares.map((item, index) => {
                                                return  <Paper
                                                    key={`accordion-detail-${item.link_description}`}
                                                    elevation={3}
                                                    style={{ padding: '20px', margin: '20px 0', backgroundColor: '#fff', boxShadow: `0 0 15px` }}
                                                >
                                                <Grid container spacing={2}>
                                                        {item.plausible?null:(
                                                            <Grid item xs={12}>
                                                                <IconButton color="warning">
                                                                    <WarningIcon />
                                                                    <Typography variant="body2" component="h6" color="warning">Current ETA implausable</Typography>
                                                                </IconButton>
                                                            </Grid>
                                                        )}
                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <AddBusinessIcon fontSize="small" color="primary" />
                                                            <Typography variant="body2" component="h2" >
                                                            Type: {item.link_description}
                                                            </Typography>
                                                            <Typography variant="body2" component="h2" >
                                                                {`Port name: ${item.port_name}` }
                                                            </Typography>
                                                            <Typography variant="body2" component="h2" >
                                                                {`Delivery description: ${item.delivery_description}` }
                                                            </Typography>
                                                            <Stack direction="column" spacing={1}>
                                                                <Chip label={`Cost: ${item.logistics_cost > 0? Math.round(item.logistics_cost): ''} ${item.currency_symbol}`} color="secondary" variant="filled" sx={{maxWidth:'50%', textAlign:'left'}}/>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <ConstructionIcon fontSize="small" color="primary" />
                                                            <Typography variant="body1" fontWeight={'bold'}>
                                                                    Spare id: {item.spare_id}
                                                            </Typography>
                                                            <Typography variant="body2">
                                                                    Part Description: {item.description}
                                                            </Typography>
                                                            <Typography variant="body2" >
                                                                    Status: {item.status}
                                                            </Typography>
                                                            <Button href={`/part/${item.spare_id}`} variant="contained" color="primary" size="small">
                                                                More information
                                                            </Button>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={4} lg={4}>
                                                            <DirectionsBoatIcon fontSize="small" color="primary" />
                                                            <Typography variant="body2" component="h2" fontWeight={'bold'}>
                                                            Shipname: {`${item.ship_name}(${item.imo})`}
                                                            </Typography>
                                                            <Typography variant="body2" component="h2">
                                                            Ship type: {`${item.ship_type.replace(/_/g, ' ')}`}
                                                            </Typography>
                                                            <Typography variant="body2" component="h2" >
                                                            Destination: {item.port_name}
                                                            </Typography>
                                                            <Button href={`/schedule/${item.imo}`} variant="contained" color="primary" size="small">
                                                                View Destinations
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                          })}
                                        </AccordionDetails>
                                    </Accordion>
                                    </Grid>
                                </>

                    }):null}
                </Grid>
        )
            
    )
}
