import React, {useState, useEffect, useCallback} from 'react';
import { MapContainer, TileLayer, useMap} from 'react-leaflet'
import {Popup, CircleMarker, Polygon } from 'react-leaflet'
import {Typography, Grid, Divider} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Slider from '@mui/material/Slider';
import L from 'leaflet';


const UpdateCenterComponent = ({ data }) => {
  const map = useMap();

  useEffect(() => {
      if (data && data.length > 0) {
          const middle = Math.floor(data.length / 2);
          const newCenter = [data[middle].lat, data[middle].lng];
          map.setView(newCenter, map.getZoom());
      }
  }, [data, map]);

  return null;
};

const Legend = ({content}) => {
  const map = useMap();

  useEffect(() => {
      const legend = L.control({ position: 'topleft' });

      legend.onAdd = () => {
        return content;
      };

      legend.addTo(map);

      // Clean up the legend when the component is unmounted
      return () => {
          map.removeControl(legend);
      };
  }, [map, content]);

  return null;
};


const legendContent = () => {
  let div = L.DomUtil.create('div', 'cirlces legend'), labels=['Central warehouse', 'Hub', "Recommended Ports", "Delivery zone"];
  let color = '#FFC000';
  div.innerHTML+="<strong style='font-size:1.2rem'></strong></br>"
  div.style = 'text-align:left';
  labels.forEach((label) => {
      // if(label === 'Links'){
      //     color = 'black';
      // }
      if(label === 'Central warehouse'){
          color = 'red';
      }
      else if(label === 'Recommended Ports'){
        color = '#104861';
      }
      else if (label === 'Delivery zone'){
          color = 'purple';
      }
      else{
          color = 'blue';
      }
      div.innerHTML += `<i style="min-width:20px; background: ${color};width: 16px; height: 16px; display: inline-block"></i><div style="font-size:1.1rem;display:inline;min-width:20px;text-align:left">  ${label}</div><br>`;
  });
  return div;
}

export default function NetworkDesign({token}) {
    const mapRef = React.useRef();
    const [loading, setLoading] = useState(true);
    const [months, setMonths] = useState(12);
    const [maxports, setMaxports] = useState(8);
    const [depotscale, setDepotscale] = useState(5);
    const [depotnm, setDepotnm] = useState(300);
    const [locations, setLocations] = useState([]);
    const [recommendedPorts, setRecommendedPorts] = useState([]);
    const [legendContentState, setLegendContentState] = useState(null);
    const [recommendedPolygons, setRecommendedPolygons] = useState([]);


    const handelMonthChange= (event, value) => {
      setMonths(value);
      getPortRecommendations(
        {
          months: value,
          maxports: maxports,
          depotscale: depotscale,
          depotnm: depotnm
        }
      );
    }
    const handelMaxPortsChange= (event, value) => {
      setMaxports(value);
      getPortRecommendations(
        {
          months: months,
          maxports: value,
          depotscale: depotscale,
          depotnm: depotnm
        }
      );
    }
    const handelDepotScaleChange= (event, value) => {
      setDepotscale(value);
      getPortRecommendations(
        {
          months: months,
          maxports: maxports,
          depotscale: value,
          depotnm: depotnm
        }
      );
    }
    const handelDepotNmChange= (event, value) => {
      setDepotnm(value);
      getPortRecommendations(
        {
          months: months,
          maxports: maxports,
          depotscale: depotscale,
          depotnm: value
        }
      );
    }
    
    const getPortRecommendations = (data) => {
      fetch(`https://port-dynamics.com/api/v1/logistics_manager/network/design/?months=${data.months}&maxports=${data.maxports}&depotscale=${data.depotscale}&depotnm=${data.depotnm}`,{
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
      })
      .then(response => response.json())
      .then(data => {
        setRecommendedPolygons(data[0].polygons);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      })
    }

    const getData= useCallback(({token}) => {
      Promise.all([
        fetch('https://port-dynamics.com/api/v1/logistics_manager/network/?d=y',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
          }

        ),
        fetch(`https://port-dynamics.com/api/v1/logistics_manager/network/design/?months=12&maxports=8&depotscale=5&depotnm=300`,{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
          }
        }),
      ])
      .then(([networkData, recommendations]) => 
        Promise.all([networkData.json(), recommendations.json()])
      )
      .then(([networkData, recommendations]) => {
        console.log(networkData);
        setLocations(networkData.locations);
        setRecommendedPorts(recommendations[0].ports);
        setRecommendedPolygons(recommendations[0].polygons)
        setLegendContentState(legendContent());
        setLoading(false);

      })
      .catch((error) => {
        console.log(error);

      });
    }, []);


    useEffect(() => {
        getData({token});

    }, [getData, token]);
    return (
      <React.Fragment>
         <Grid container spacing={2} style={{marginTop:'10px',}}>
              <Grid item xs={12} lg={12}>
                <Typography component="h6" variant="h6">Recommended hub optimization settings</Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography gutterBottom>Timeframe in months</Typography>
                <Slider value={months} defaultValue={12} aria-label="Default" valueLabelDisplay="auto" color="secondary" min={3} max={24} onChange={handelMonthChange}/>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography gutterBottom>Maximum no of hubs</Typography>
                <Slider value={maxports} defaultValue={8} aria-label="Default" valueLabelDisplay="auto" color="secondary" min={1} max={15} onChange={handelMaxPortsChange}/>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography gutterBottom>Minimum visits to depot</Typography>
                <Slider value={depotscale} defaultValue={5} aria-label="Default" valueLabelDisplay="auto" color="secondary" min={1} max={20} onChange={handelDepotScaleChange}/>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <Typography gutterBottom>Maximum distance to depot</Typography>
                <Slider value={depotnm} defaultValue={300} aria-label="Default" valueLabelDisplay="auto"  color="secondary" min={1} max={600} onChange={handelDepotNmChange}/>
              </Grid>            

          </Grid>
          <Divider/>
         {loading ? 
            <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
              <Grid item xs={12} m={6}>
                <CircularProgress size={100}/>
              </Grid>
            </Grid>:
            <Grid container spacing={3} style={{marginTop: '20px', textAlign:'center'}}>
               <Grid item xs={12}>
               <MapContainer  zoom={3} center={[51.505, -0.09]} scrollWheelZoom={true} style={{ height: '80vh', minWidth: "100%", marginTop:'5px' }} whenCreated={(map) => { mapRef.current = map; }}>
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <UpdateCenterComponent data={locations}/>
                  {recommendedPolygons? recommendedPolygons.map((polygon_details, index) => (
                    <Polygon key={`${polygon_details.n}-polygon`} pathOptions={{color: 'purple', fillOpacity:0.02 }} positions={polygon_details.coords}>
                        <Popup>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="p" variant="p">Polygon : {polygon_details.n}</Typography>
                                </Grid>
                            </Grid>
                        </Popup>
                    </Polygon>
                  )):null}
                    {locations? locations.map((location, index) => (
                    <CircleMarker key={`${location.location_name}-circle1`} center={[location.lat, location.lng]} pathOptions={{color:location.colour, fillColor: location.colour, fillOpacity: 1}} radius={4}>
                        <Popup>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="p" variant="p">ID: {location.location_name}</Typography>
                                    <Typography component="p" variant="p">Name: {location.port_name}</Typography>
                                </Grid>
                            </Grid>
                        </Popup>
                    </CircleMarker>
                  )):null}
                  {recommendedPorts? recommendedPorts.map((point, index) => (
                    <CircleMarker key={`${point.port_name}-circle`} center={[point.lat, point.lng]} pathOptions={{color:point.colour, fillColor: point.colour, fillOpacity: 1}} radius={4}>
                        <Popup>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Typography component="p" variant="p">Hub id: {point.hub_id}</Typography>
                                    <Typography component="p" variant="p">Name: {point.port_name}</Typography>
                                </Grid>
                            </Grid>
                        </Popup>
                    </CircleMarker>
                  )):null}

                  {legendContentState? <Legend content={legendContentState}/>:null}                

              </MapContainer>
              </Grid>
          </Grid>
         
         
         }
    </React.Fragment>
    );
}