import * as React from 'react';
import { styled } from '@mui/material/styles';
import {Grid, Box} from '@mui/material';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


export default function UploadFleet() {
    return (
        <Grid container spacing={2} sx={{marginTop:'5%'}}>
            <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor:'#efefef' }}>
            <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    sx={{margin: '10%'}}
                    size="large"
                    >
                    Upload a fleet
                    <VisuallyHiddenInput
                        type="file"
                    />
                    </Button>
          </Box>
            </Grid>
        </Grid>
    );
    
}