import React, {useState, useEffect, } from 'react';
import Map from '../components/map';
import { Popup, CircleMarker, Polyline, Polygon } from 'react-leaflet'
import {Typography, Grid,} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import L from 'leaflet';




export default function Network({token}) {
    const [loading, setLoading] = useState(true);
    const [layers, setLayers] = useState([]);
    const [mapRadius, setMapRadius] = useState(undefined);
    const [locations, setLocations] = useState([]);
    const [deliveryPolygons, setDeliveryPolygons] = useState([]);
    const [links, setLinks] = useState([]);
    const [ports, setPorts] = useState([]);


    const createPolygonLayer = (data) => {
      if(data){
        console.log(data);
          data.forEach((polygon_details, index) => {
              setLayers(prevData => [
                ...prevData,
                  <React.Fragment key={`${polygon_details.label}-polygon`}>
                    <Polygon pathOptions={{color: 'green', fillOpacity:0.02 }} positions={polygon_details.positions.map(position=>[position.lat, position.lng])}>
                        <Popup>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography component="p" variant="p">Details: {polygon_details.label}</Typography>
                                </Grid>
                            </Grid>
                        </Popup>
                    </Polygon>
                </React.Fragment>
              ])
          })
      }
    };
    

    const createLocationLayer = (data) => { 
      if(data){
          data.map((point, index) => (
            setLayers(prevData => [
              ...prevData,
              <React.Fragment key={`${point.location_name}-zone`}>
                  <CircleMarker key={`${point.location_name}-circle1`} center={[point.lat, point.lng]} pathOptions={{color:point.colour, fillColor: point.colour, fillOpacity: 1}} radius={mapRadius}>
                      <Popup>
                          <Grid container spacing={2}>
                              <Grid item xs={12}>
                                  <Typography component="p" variant="p">ID: {point.location_name}</Typography>
                                  <Typography component="p" variant="p">Name: {point.location_name}</Typography>

                              </Grid>
                          </Grid>
                      </Popup>

                  </CircleMarker>
              </React.Fragment>
            ])
          ));
        }
    }

    const createPortsLayer = (data) => { 
      if(data){
          data.map((point, index) => (
            setLayers(prevData => [
              ...prevData,
              <React.Fragment key={`${point.port_name}-zone`}>
                  <CircleMarker key={`${point.port_name}-circle1`} center={[point.lat, point.lng]} pathOptions={{color:"orange", fillColor: "orange", fillOpacity: 1}} radius={mapRadius}>
                      <Popup>
                          <Grid container spacing={2}>
                              <Grid item xs={12}>
                                  <Typography component="p" variant="p">Name: {point.port_name}</Typography>

                              </Grid>
                          </Grid>
                      </Popup>

                  </CircleMarker>
              </React.Fragment>
            ])
          ));
        }
    }
    const createLinksLayer = (data) => {
      if(data){
          data.map((link, index) => (
            setLayers(prevData => [
              ...prevData,
                <React.Fragment key={`${link.hub_id}-fragment3`}>
                    <Polyline key={`${link.hub_id}-line1`} pathOptions={{color:'black'}} positions={[[link.lat0, link.lng0], [link.lat1, link.lng1]]}>
                        <Popup>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <>
                                        Location ID: {link.location_id} <br />
                                        Hub ID: {link.hub_id} <br />
                                        {link.label}
                                    </>
                                </Grid>
                            </Grid>
                        </Popup>
                    </Polyline>
                </React.Fragment>
            ])
          ));
      }
    }


    const legendContent = () => {
        let div = L.DomUtil.create('div', 'cirlces legend'), labels=['Links', 'Delivery Zones','Hub', 'Warehouse', "Ports"];
        let color = '#FFC000';
        div.innerHTML+="<strong style='font-size:1.2rem'></strong></br>"
        div.style = 'text-align:left';
        labels.forEach((label) => {
            if(label === 'Links'){
                color = 'black';
            }
            else if(label === 'Delivery Zones'){
                color = 'green';
            }
            else if(label === 'Hub'){
                color = 'red';
            }
            else if(label === 'Ports'){
              color = 'orange';
            }
            else{
                color = 'blue';
            }
            div.innerHTML += `<i style="min-width:20px; background: ${color};width: 16px; height: 16px; display: inline-block"></i><div style="font-size:1.1rem;display:inline;min-width:20px;text-align:left">  ${label}</div><br>`;
        });
        return div;
    }

    useEffect(() => {
        setMapRadius(4);
        fetch('https://port-dynamics.com/api/v1/logistics_manager/network/?d=y',{
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Token ${token}`
          }
        }

      )
      .then(response => response.json())
      .then(data => {
        setLocations(data.locations);
        setDeliveryPolygons(data.DeliveryPolygons);
        setLinks(data.Links);
        setPorts(data.ports);
      }
      )
      .catch((error) => {
        console.log(error);
      }
      );

    }, [token]);

    useEffect(() => {
      const [locationsData, deliveryPolygonsData, linksData, portsData] = [locations, deliveryPolygons, links, ports];
      if(locations && deliveryPolygons && links && ports){
        createPolygonLayer(deliveryPolygonsData);
        createPortsLayer(portsData);
        createLocationLayer(locationsData);
        createLinksLayer(linksData);

        setLoading(false);
      }
    }
    , [locations, deliveryPolygons, links, ports]);

    return (
      <React.Fragment>
          {loading ? 
            <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
              <Grid item xs={12} m={6}>
                <CircularProgress size={100}/>
              </Grid>
            </Grid>:
            <Grid container spacing={3} style={{marginTop: '20px', textAlign:'center'}}>
              <Grid item xs={12}>
                {layers && locations && mapRadius? <Map height={'50vh'} zoom={3} layers={layers} mapCenterData={locations} legendContent={legendContent}/>:null}
              </Grid>
            </Grid>
        }
    </React.Fragment>
    );
}