import React, { useEffect, useState } from "react";
import {Typography, Grid, Paper, Button} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
import SearchIcon from '@mui/icons-material/Search';

export default function Fleet({token, fleetData}){
    const [fleet, setFleet] = useState([]);
    const [oldFleet, setOldFleet] = useState([]);
    const [fleetLookup, setFleetLookup] = useState([]);
    const[loading, setLoading] = useState(true);
    const filterFleet = (event, newVal) => {
        if(!newVal){
            setFleet(oldFleet);
            return
        }
        const filteredFleet = fleet.filter(vessel => vessel.ship_name? vessel.ship_name.includes(newVal.label): vessel.ship_name);
        if(!filteredFleet.length){
            setFleet([oldFleet]);
        }else{
            setFleet(filteredFleet);
        }
    }
    useEffect(() => {
        const fleetLookupData = fleetData.map((ship) => {
            return {
                id: ship.imo,
                label: `${ship.ship_name}`
            }
        });
        setFleetLookup(fleetLookupData);
        setFleet(fleetData);
        setOldFleet(fleetData);
        setLoading(false);
    }
    , [fleetData]);
    return (
        <Grid container spacing={2} sx={{marginTop:'10px'}}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <SearchIcon/>
                <Autocomplete
                    disablePortal
                    id="search-by-id"
                    options={fleetLookup}
                    sx={{ width: 300 }}
                    getOptionLabel={(option) => option.label}
                    onChange={filterFleet}
                    renderInput={params =>   
                        <TextField 
                            {...params}
                            label="Search by ship name"
                            variant="outlined"
                            fullWidth
                        />
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id} // To properly compare options
                    style={{width:'100%'}}
                />
            </Grid>
            {loading ? 
             <Grid container spacing={2} style={{marginTop:'200px', textAlign:'center'}}>
                <Grid item xs={12} m={6}>
                <CircularProgress size={100}/>
                </Grid>
            </Grid>
            :
                fleet.length?fleet.map((vessel, index) => {
                    return (
                        <Grid item xs={12} s={12} md={4} lg={4} key={index}>
                            <Paper sx={{padding: '10px'}}>
                                <DirectionsBoatIcon fontSize="small" color="secondary" />
                                <Typography variant="h3">Name: {vessel.ship_name}</Typography>
                                <Grid container>
                                     <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography variant="body1">IMO: {vessel.imo}</Typography>
                                        <Typography variant="body1">Ship type: {vessel.ship_type}</Typography>
                                        <Typography variant="body1">DWT: {vessel.dwt}</Typography>
                                        <Button variant="contained" size="small" color="primary" href={`/schedule/${vessel.imo}`}>View destinations</Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Typography variant="body1">Logistics: {vessel.logistics}</Typography>
                                        <Typography variant="body1">Customer name: {vessel.customer_name}</Typography>
                                        <Typography variant="body1">Supervisor: {vessel.supervisor}</Typography>
                                        <Typography variant="body1">Operating region: {vessel.operating_region}</Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    )
                })
            
            
           :null }
        </Grid>
    )
}